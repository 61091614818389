import '@/shared/utils/prelude';
import '../styles/globals.css';
import '@rainbow-me/rainbowkit/styles.css';
import React from 'react';
import { type AppProps } from 'next/app';
import Head from 'next/head';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';
import { Toaster } from 'sonner';
import FallbackLayout from '@/shared/components/organisms/FallbackLayout';
import { Provider as RainbowKitWrappedProvider } from '@/shared/components/RainbowKit';
import { FundingSDKProvider, PriceOracleProvider } from '@/shared/hooks';
import usePlausible from '@/shared/hooks/usePlausible';
import { initSentry } from '@/shared/utils';
import { defaultRainbowConfig } from '@/shared/utils/rainbowkit';

initSentry();

function App({ Component, pageProps }: AppProps): JSX.Element {
  // Initialize analytics
  usePlausible();
  ReactGA.initialize('UA-195306681-2');

  return (
    <RainbowKitWrappedProvider config={defaultRainbowConfig}>
      <FundingSDKProvider>
        <PriceOracleProvider>
          <Head>
            <link rel="icon" href="/chainflip-favicon.ico" sizes="any" />
            <link rel="icon" href="/chainflip-favicon.svg" type="image/svg+xml" />
            <title>Chainflip Auctions</title>
          </Head>
          <Sentry.ErrorBoundary
            // eslint-disable-next-line react/no-unstable-nested-components
            fallback={({ error, resetError }) => {
              const errorMessage = (error as Error).message;
              return <FallbackLayout message={errorMessage} onClose={resetError} />;
            }}
          >
            <Component {...pageProps} />
            <Toaster expand richColors closeButton theme="dark" visibleToasts={10} />
          </Sentry.ErrorBoundary>
        </PriceOracleProvider>
      </FundingSDKProvider>
    </RainbowKitWrappedProvider>
  );
}

export default App;
